import { store } from '../store/store'

export default async (to, from) => {
    await store.commit('setValue', { type: 'loading', value: false })

    if (!to.meta.auth) {
      await store.commit('setValue', { type: 'logging', value: true })
      // eslint-disable-next-line no-undef
      axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`).then((resp) => {
        // eslint-disable-next-line no-undef
          axios({
              method: 'post',
              url: '/user',
              data: {},
              validateStatus: (status) => {
                  return true
              },
          }).catch(error => {

          }).then(async response => {
              if (response.data.error) {
                  return false
              }

              if (response.data.id) {
                  await store.commit('setValue', { type: 'login', value: true })
                  await store.commit('setValue', { type: 'user', value: response.data })
              }

              // this is now called!
          }).then(async () => {
            await store.commit('setValue', {
              type: 'logging',
              value: false
            })
          })
      })
    }
}
