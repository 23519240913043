'use strict'

import Vue from 'vue'
import axios from 'axios'
import { i18n } from './i18n'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = true
axios.defaults.headers.common['Accept-Language'] = i18n.locale
axios.defaults.withCredentials = true
// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.apiUrl || process.env.baseURL || ''
/* const token = document.head.querySelector('meta[name="csrf-token"]')
const frame = document.head.querySelector('meta[name="frame"]') */

/* if (token) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
} */
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_API || process.env.baseURL || '',
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)
_axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie?&init=1`).then(response => {
  //
})
_axios.interceptors.request.use(
    function (config) {
      config.headers.common['Accept-Language'] = i18n.locale
      // if (process.env.DEV) {
        const token = localStorage.getItem('accessToken')
        // If token is found
      if (token && token !== 'undefined') {
        let parsedToken;
        try {
          parsedToken = JSON.parse(token);
        } catch (e) {
          parsedToken = token; // If parsing fails, use the token as is
        }
        config.headers.Authorization = `Bearer ${parsedToken}`
      }
     // }
      // Do something before request is sent
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    },
)

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response
    },
    function (error) {
      const originalRequest = error.config
      if ((error && error.response && (error.response.status === 401 || error.response.status === 419)) && !originalRequest._retry) { // if the error is 401 and hasent already been retried
        _axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
          //
        })

        originalRequest._retry = true // now it can be retried
        // window.location.href = '/login'
      }
      if (error && error.response && error.response.status === 404 && !originalRequest._retry) {
        originalRequest._retry = true
        window.location.href = '/404'
        return
      }
      // Do something with response error
      return Promise.reject(error)
    },
)

Plugin.install = function (Vue/*, options */) {
  /* Vue.isframe = frame ? frame.content : false
  window.isframe = frame ? frame.content : false */
  Vue.axios = _axios
  window.axios = _axios
  // eslint-disable-next-line no-undef
  _axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`).then(response => {
    //
  })
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      },
    },
    $axios: {
      get () {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
